export default function (Component) {
      Component.options.frontmatter = {
  "published": "2019-06-10T00:00:00.000Z",
  "tags": [
    "design",
    "general"
  ],
  "image": require('./david-van-dijk-255503-unsplash.jpg'),
  "title": "Simple Hard Things",
  "excerpt": "<p>Simple things are easy; everyone says so. However, in reality, it may not be so.</p>\n"
}
    }